/* @font-face {
    font-family: 'AvenirMedium';
    src: url('AvenirNextRoundedStd-Med.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'AvenirBold';
    src: url('AvenirNextRoundedStd-Bold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
} */
html, button{
    background-color: #212121;
    font-family: "Inter", AvenirMedium;
    color: #fff;
}
header{
    position: fixed; 
    left: 0;            
    right: 0; 
    top: 0;          
    background-color: #ffffff;
    height: 10vh;
    display: flex;
    align-items: center;
}
#title{
    text-decoration: none;
    display: flex;
    align-items: center;
}
#vertSpacer{
    height: 15vh;
}

header h1{
    font-family: "Inter", AvenirBold;
    color: #18206F;
    margin-left: 1rem;
}
.expand{ 
    display: flex;
    flex-grow: 1;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
}
header a, button {
    padding: 1rem;
    text-decoration: none;
}
a, button {
    color: #D4AF37;
}
button{
    border: none;
    background: none;
    cursor: pointer;
    font-size: 100%;
}
main{
    text-align: center;
}
footer {
    background-color: #000;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 1rem;
    gap: 2rem;
}
.stuff{
    width: 10rem;
    height: 10rem;
    background-color: #18206F;
    text-align: center;
    border-radius: 1rem;
    transition: all 1s;
}
.stuff:hover{
    background-color: #D4AF37;
    color:#18206F;
}